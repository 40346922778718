<template>
    <v-row class="h-100" no-gutters>
        <v-col md="3">
            <div class="left_info h-100">
              <div class="left_info_container">
                <div id="input_container">
                  <v-table density="compact">
                    <thead>
                      <tr>
                        <th class="text-left" colspan="2">
                          Данные запроса
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="user_query.name && user_query.name.length > 0">
                        <td>Наименование</td><td>{{ user_query.name }}</td>
                      </tr>
                      <tr v-if="user_query.art && user_query.art.length > 0">
                        <td>Артикул</td><td>{{ user_query.art }}</td>
                      </tr>
                      <tr v-if="user_query.pumpType && user_query.pumpType.length > 0">
                        <td>Серия</td><td>{{ user_query.pumpType }}</td>
                      </tr>
                      <tr v-if="user_query.vid && user_query.vid.length > 0">
                        <td>Тип насоса</td><td>{{ user_query.vid }}</td>
                      </tr>
                      <tr v-if="user_query.systemType && user_query.systemType.length > 0">
                        <td>Тип системы</td><td>{{ user_query.systemType }}</td>
                      </tr>
                      <tr>
                        <td>Тип жидкости</td><td>Чистая вода</td>
                      </tr>
                      <tr v-if="user_query.Q > 0 || user_query.H > 0">
                        <td>Работая точка</td><td>Q: {{ user_query.Q }}м<sup>3</sup>/ч, H: {{ user_query.H }}м</td>
                      </tr>


                      <tr>
                        <td>Количество основных насосов</td><td>{{ user_query.pumps_count_basic }}</td>
                      </tr>
                      <tr>
                        <td>Количество резервных насосов</td><td>{{ user_query.pumps_count_spare }}</td>
                      </tr>
                      <tr v-if="parseFloat(user_query.work_q_z) > 0 || parseFloat(user_query.work_h_z) > 0">
                        <td>Работая точка жокей насоса</td><td>Q: {{ user_query.work_q_z }}м<sup>3</sup>/ч, H: {{ user_query.work_h_z }}м</td>
                      </tr>
                      <tr v-if="user_query.reg_type.length > 0">
                        <td>Тип регулирования</td><td>{{ user_query.reg_type }}</td>
                      </tr>
                      <tr v-if="user_query.t_max_over_70">
                        <td>Tmax рабочей среды, более 70°С</td><td>да</td>
                      </tr>
                      <tr v-if="parseFloat(user_query.p_in) > 0">
                        <td>Входное давление</td><td>{{ user_query.p_in }} бар</td>
                      </tr>
                      <tr v-if="parseFloat(user_query.p_max) > 0">
                        <td>Максимальное рабочее давление</td><td>{{ user_query.p_max }} бар</td>
                      </tr>
                      <tr v-if="user_query.dn_vsas.length > 0">
                        <td>DNвсас</td><td>{{ user_query.dn_vsas }}</td>
                      </tr>
                      <tr v-if="user_query.dn_nap.length > 0">
                        <td>DNнап</td><td>{{ user_query.dn_nap }}</td>
                      </tr>

                      <tr v-if="user_query.additional_auto.length > 0">
                        <td>Доп. требования по автоматизации</td><td>{{ user_query.additional_auto }}</td>
                      </tr>
                      <tr v-if="user_query.additional_comp.length > 0">
                        <td>Доп. требования по комплектации</td><td>{{ user_query.additional_comp }}</td>
                      </tr>







                    </tbody>
                  </v-table>
                  <v-table density="compact" v-if="wp_in_gr && user_query.Q && user_query.H && (user_query.Q > 0 || user_query.H > 0)">
                    <thead>
                      <tr>
                        <th class="text-left" colspan="2">
                          Данные гидравлики <span class="text-muted">(раб. точка)</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Расход</td><td>{{ user_query.Q }}м<sup>3</sup>/ч</td>
                      </tr>
                      <tr>
                        <td>Напор</td><td>{{head_h.toFixed(2)}}м</td>
                      </tr>
                      <tr>
                        <td>КПД</td><td>{{eff.toFixed(2)}}%</td>
                      </tr>
                      <tr>
                        <td>NPSHr</td><td>{{npshr.toFixed(2)}}м</td>
                      </tr>
                    </tbody>
                  </v-table>
                </div>
                <v-img class="pump_img" v-if="img.length > 0" width="100%" contain :src="img">
                    <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                            <v-progress-circular
                            color="grey-lighten-4"
                            indeterminate
                            ></v-progress-circular>
                        </div>
                    </template>
                </v-img>
                <div class="buttons">
                  <v-btn v-if="svgready" @click="createPDF" block color="primary" prepend-icon="mdi-file-pdf-box">Скачать PDF</v-btn>
                  <form id="graphform" :action="pdf_url" method="post" target="_blank">
                    <input type="hidden" name="graph" id="input_graph">
                    <input type="hidden" name="graph1" id="input_graph1">
                    <input type="hidden" name="graph2" id="input_graph2">

                    <input type="hidden" name="input_data" id="input_data">

                    <input type="hidden" name="unit_name" id="unit_name">
                    <input type="hidden" name="tech_data" id="tech_data">
                    <input type="hidden" name="ctrl_box_data" id="ctrl_box_data">

                  </form>
                </div>
              </div>
            </div>
        </v-col>
        <v-col md="9">
            <v-tabs v-model="tab" bg-color="var(--root-light)" color="var(--root-color)" >
                <v-tab value="one">Графики</v-tab> 
                <v-tab value="four">Общие технические данные установки</v-tab>
                <v-tab value="two">Общие данные по шкафу управления</v-tab>
                <v-tab value="three">Электрическая схема</v-tab>
                <v-tab value="five">Чертежи</v-tab>
                <v-tab value="price_tab">УЗНАТЬ ЦЕНУ</v-tab>
                
                
            </v-tabs>
            <v-card-text >
                <v-tabs-window v-model="tab">
                    <v-tabs-window-item value="one" class=" h-100">
                        <div class="scroll_block h-100">
                            <v-row class="mnull">
                              <v-col md="12">
                                <div class="line_colors_map">
                                  <span>Рабочие насосы</span>
                                  <span>Резервные насосы</span>
                                  <span>Жокей насос</span>
                                  <span>Рабочая точка</span>
                                </div>
                              </v-col>
                              <v-col md="12">
                                <div id="synced-charts">
                                    <apexchart  type="line" height="250" :options="chartOptions" :series="series"></apexchart>
                                    <apexchart type="line" height="250" :options="chartOptionsEff" :series="seriesEff"></apexchart>
                                    <!--<apexchart type="line" height="250" :options="chartOptionsPower" :series="seriesPower"></apexchart>-->
                                    <apexchart type="line" height="250" :options="chartOptionsNPSH" :series="seriesNPSH"></apexchart>
                                </div>
                              </v-col>
                              <v-col md="12">
                                <v-alert title="Внимание!" type="info">Поставщик может только рекомендовать заказчику оборудования с требуемыми характеристиками. <br><br>Ответственность за окончательный выбор оборудования лежит на Заказчике, поскольку даже после тщательного анализа опросных листов и получения дополнительной информации для Поставщика всегда остается неопределенность в условиях эксплуатации оборудования. При этом Заказчик несет ответственность за достоверность предоставленной информации.</v-alert>
                              </v-col>
                            </v-row>
                            
                        </div>
                    </v-tabs-window-item>

                    <v-tabs-window-item value="two" class="h-100">
                        <div class="text_block" v-html="os">
                        </div>
                    </v-tabs-window-item>

                    <v-tabs-window-item value="three" class="h-100">
                      <div class="text_block" v-html="es">
                      </div>
                      
                    </v-tabs-window-item>
                    <v-tabs-window-item value="four" class="h-100">
                      <div class="text_block" v-html="ot">
                      </div>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="five" class="h-100">
                      <v-alert class="white_links" type="info">Обращайтесь в компанию АДЛ <a href="mailto:info@adl.ru">info@adl.ru</a>, <a href="tel:+74959378968">+7 (495) 937-89-68</a></v-alert>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="price_tab" class="h-100">
                      <v-alert title="" type="info" class="not_found_alert">Для уточнения цены, просим направить сформированное ТКП или заполненные <a href="https://adl.ru/upload/adl-doc/NO_proektirovanie_nasos_stancii.doc" target="_blank">Опросные листы</a> и отправить на почту <a href="mailto:info@adl.ru">info@adl.ru</a> с указанием контактных данных организаций, а также наименованием и адресом объекта, на которое подбирается оборудование.</v-alert>
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-card-text>
        </v-col>
    </v-row>
    <canvas id="test_canvas" ref="canvas"></canvas>
</template>
<script lang='ts'>
import { defineComponent } from 'vue'
import axios from "axios";
import jsPDF from "jspdf";
//import autoTable from "jspdf-autotable";

export default defineComponent({
  components: {
  },
  props: {
    pump:{
      default: new Object()
    },
    work_point:{
      default: {
        x: -1,
        y: -1
      }
    },
    user_query: {
      default: {
        Q: 0,
        H: 0,
        name: '',
        art: '',
        pumpType: '',
        vid: '',
        systemType: '',
        pumps_count_basic: 0,
        pumps_count_spare: 0,
        work_q_z: '',
        work_h_z: '',
        zokei_off: false,
        reg_type: '',
        system_type: '',
        t_max_over_70: false,//температура больше 70
        p_in: '',//входное давление
        p_max: '',//максимальное рабочее давление
        dn_vsas: '',//диаметр DNвсас
        dn_nap: '',//диаметр DNнап
        additional_auto: '',//доп требования
        additional_comp: '',//доп требования
      }
    },
    zokei:{
      default:{
        art: '',
        name: '',
        QH: ''
      }
    }
  },
  data () {
    return {
      loading: false,
      img: '',
      tab: null,
      characteristics:new Array(0),
      body_size:new Array(0),
      //данные гидравлики
      head_h: 0,
      eff: 0,
      power: 0,
      npshr: 0,
      //попадает ли рабочая точка на график
      wp_in_gr: true,
      //массив с графиками в base64
      png_images: new Array(0),
      os: '',//Общие данные по шкафам управления
      ot: '',//Общие технологические данные
      pv: '',//Примерные виды установок
      es: '',//Электрические схемы
      //url для pdf
      pdf_url:'',
      svgready: false,
      //график с рабочей точкой
      series: [
        {
          name: 'Рабочая точка',
          type: 'scatter',
          data: [{
            x: 0,
            y: 0
          }]
        },
        {
          name: 'Line',
          type: 'line',
          data: []
        }
      ],
      chartOptions: {
        chart: {
          height: 250,
          type: 'line',
          id: 'line-1',
          //group: 'graph',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        },
        fill: {
          type:'solid',
        },
        markers: {
          size: [6, 0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        },
        tooltip: {
          //shared: true,
          shared: false,
          x: {
            show: false
          },
          intersect: true,
          //inverseOrder:true
        },
        stroke: {
          width: 2,
          curve: 'smooth',
          colors: ['#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5']
          //curve: 'straight'
        },
        legend: {
          show: false
        },
        xaxis: {
          type: 'numeric',
          min: 0,
          max: 1,
          tickAmount: 10,
          title: {
            text: 'Расход [м³/ч]'
          },
          axisTicks: {
              show: true,
              borderType: 'solid',
              //color: '#78909C',
              height: "100%",
              offsetX: 0,
              offsetY: 0
          },
        },
        yaxis: {
          title: {
            text: 'Напор [м]'
          },
          min: 0
          //tickAmount: 10
        }
    },


        //график КПД
        seriesEff: [
            {
              name: 'Line',
              type: 'line',
              data: new Array(0)
            }
        ],
        chartOptionsEff: {
            chart: {
              id: 'line-2',
              height: 250,
              type: 'line',
              zoom: {
                enabled: false
              },
              toolbar: {
                show: false
              },
            animations: {
              enabled: false
            }
            },
            dataLabels: {
              enabled: false
            },
            fill: {
              type:'solid',
            },
            markers: {
              size: [6, 0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
            },
            tooltip: {
              shared: false,
              intersect: true,
            },
            stroke: {
              width: 2,
              curve: 'smooth',
              colors: ['#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5']
            },
            legend: {
              show: false
            },
            xaxis: {
              type: 'numeric',
              min: 0,
              max: 1,
              tickAmount: 10,
              title: {
                text: 'Расход [м³/ч]'
              },
              axisTicks: {
                  show: true,
                  borderType: 'solid',
                  height: "100%",
                  offsetX: 0,
                  offsetY: 0
              },
            },
            yaxis: {
              title: {
                text: 'КПД [%]'
              },
              min: 0
            }
        },

        //график Мощности
        seriesPower: [
            {
              name: 'Line',
              type: 'line',
              data: []
            }
        ],
        chartOptionsPower: {
            chart: {
              id: 'line-3',
              //group: 'graph',
              height: 250,
              type: 'line',
              zoom: {
                enabled: false
              },
              toolbar: {
                show: false
              },
            animations: {
              enabled: false
            }
            },
            fill: {
              type:'solid',
            },
            markers: {
              size: [6, 0]
            },
            tooltip: {
              shared: false,
              intersect: true,
            },
            stroke: {
              width: 2,
              curve: 'smooth'
            },
            legend: {
              show: false
            },
            xaxis: {
              type: 'numeric',
              min: 0,
              max: 1,
              tickAmount: 10,
              title: {
                text: 'Расход [м³/ч]'
              },
              axisTicks: {
                  show: true,
                  borderType: 'solid',
                  height: "100%",
                  offsetX: 0,
                  offsetY: 0
              },
            },
            yaxis: {
              title: {
                text: 'Мощность [кВт]'
              },
              min: 0
            }
        },

        //график КПД NPSH
        seriesNPSH: [
            {
              name: 'Line',
              type: 'line',
              data: new Array(0)
            }
        ],
        chartOptionsNPSH: {
            chart: {
              id: 'line-4',
              //group: 'graph',
              height: 250,
              type: 'line',
              zoom: {
                enabled: false
              },
              toolbar: {
                show: false
              },
              animations: {
                enabled: false
              }
            },
            fill: {
              type:'solid',
            },
            markers: {
              size: [6, 0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
            },
            tooltip: {
              shared: false,
              intersect: true,
            },
            stroke: {
              width: 2,
              curve: 'smooth',
              colors: ['#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5','#26E7A5']
            },
            legend: {
              show: false
            },
            xaxis: {
              type: 'numeric',
              min: 0,
              max: 1,
              tickAmount: 10,
              title: {
                text: 'Расход [м³/ч]'
              },
              axisTicks: {
                  show: true,
                  borderType: 'solid',
                  height: "100%",
                  offsetX: 0,
                  offsetY: 0
              },
            },
            yaxis: {
              title: {
                text: 'NPSHr [м]'
              },
              min: 0
            }
        },
    }
  },
  methods: {
    drawHQ(){
      //строим график напора и высоты
      let tmp_pump = (this.pump as any);
      if(tmp_pump.UF_HEAD_H_JSON && tmp_pump.UF_HEAD_H_JSON.length > 0){
        let HQ_obj = JSON.parse(tmp_pump.UF_HEAD_H_JSON);//получаем известные точки
        let HQ_arr = [] as any;

        let HQ_arr_more = [] as any;

        let x_min = 99999;
        let x_max = 0;

        let y_min = 99999;
        Object.entries(HQ_obj).forEach(([key, value]) => {//делаем массив известных точек
          HQ_arr.push({x:parseFloat(key),y:parseFloat(value as any)})
          //x_min = Math.min(parseFloat(key as any), x_min)//максимум
          x_max = Math.max(parseFloat(key as any), x_max)//минимум

          y_min = Math.min(parseFloat(value as any), y_min)
        });
        if(this.work_point.x){
          //x_min = Math.min(this.work_point.x, x_min)
          x_max = Math.max(this.work_point.x, x_max)
        }

        if(this.work_point.y) y_min = Math.min(this.work_point.y, y_min)

        //проверяем попадает ли точка на график
        //this.wp_in_gr = !(x_min == this.work_point.x || x_max == this.work_point.x);

        //данные гидравлики для напора
        //let Q = this.work_point.x;
        //не подходит для некоторых графиков
        //this.head_h = parseFloat(tmp_pump.UF_H0) + parseFloat(tmp_pump.UF_S1)*Q + parseFloat(tmp_pump.UF_S2)*(Q*Q)


        //сортируем точки
        HQ_arr.sort(function(a:any, b:any) {
          return a.x - b.x;
        });

        this.chartOptions.xaxis.min = x_min;
        //this.chartOptions.yaxis.min = y_min;
        
        //применяем точки к графику
        this.series = [
            {
              name: 'Рабочая точка',
              type: 'scatter',
              data: [this.work_point]//рабочая точка
            },
            {
              name: 'Line',
              type: 'line',
              data: HQ_arr
            }
        ];

        //напор если один насос
        this.head_h = this.findInGraph(HQ_arr, this.work_point.x)

        //дорисовываем остальные графики
        let all_pumps = parseInt(this.user_query.pumps_count_basic.toString()) + parseInt(this.user_query.pumps_count_spare.toString())
        for(let i=2;i<=all_pumps; i++){
          let temp_points = new Array(0);
          HQ_arr.forEach((val: any, index: any) => {
            temp_points.push({x: index == 0 ? val.x : val.x*i, y:val.y})
            x_max = Math.max(val.x*i, x_max);
          })

          if(i>this.user_query.pumps_count_basic){
            this.chartOptions.stroke.colors[i] = '#FEBB3B'
          }

          if(i==this.user_query.pumps_count_basic){
            //let Q = this.work_point.x/i;
            //this.head_h = parseFloat(tmp_pump.UF_H0) + parseFloat(tmp_pump.UF_S1)*Q + parseFloat(tmp_pump.UF_S2)*(Q*Q)
            this.head_h = this.findInGraph(temp_points, this.work_point.x)
          }

          this.series.push({
              name: 'Line'+i,
              type: 'line',
              data: temp_points
          })
        }

        //рисуем график жокей насоса
        if(this.zokei.QH.length > 0){
          let z_HQ_obj = JSON.parse(this.zokei.QH);
          let temp_points = new Array(0);
          Object.entries(z_HQ_obj).forEach(([key, value]) => {
            temp_points.push({x:parseFloat(key),y:parseFloat(value as any)})
            x_min = Math.min(parseFloat(key as any), x_min)
            x_max = Math.max(parseFloat(key as any), x_max)

            y_min = Math.min(parseFloat(value as any), y_min)
          })
          //this.chartOptions.yaxis.min = y_min;
          temp_points.sort(function(a:any, b:any) {
            return a.x - b.x;
          });
          this.series.push({
              name: 'Line'+(all_pumps+2),
              type: 'line',
              data: temp_points
          })
          this.chartOptions.stroke.colors[all_pumps+1] = '#FF6077'
        }

        
        x_min = 0;

        this.chartOptions.xaxis.min = x_min;
        this.chartOptions.xaxis.max = x_max;

        this.chartOptionsEff.xaxis.min = x_min;
        this.chartOptionsEff.xaxis.max = x_max;

        this.chartOptionsPower.xaxis.min = x_min;
        this.chartOptionsPower.xaxis.max = x_max;

        this.chartOptionsNPSH.xaxis.min = x_min;
        this.chartOptionsNPSH.xaxis.max = x_max;
      }
    },
    drawEff(){
      let tmp_pump = (this.pump as any);
      if(tmp_pump.UF_EFF_JSON && tmp_pump.UF_EFF_JSON.length > 0){
        let HQ_obj = JSON.parse(tmp_pump.UF_EFF_JSON);//получаем известные точки
        let HQ_arr = [] as any;

        let x_min = 99999;
        let x_max = 0;
        Object.entries(HQ_obj).forEach(([key, value]) => {//делаем массив известных точек
          HQ_arr.push({x:parseFloat(key),y:parseFloat(value as any)})
          x_min = Math.min(parseFloat(key as any), x_min)//максимум
          x_max = Math.max(parseFloat(key as any), x_max)//минимум
        });
        if(this.work_point.x){
          x_min = Math.min(this.work_point.x, x_min)
          x_max = Math.max(this.work_point.x, x_max)
        }


        //сортируем точки
        HQ_arr.sort(function(a:any, b:any) {
          return a.x - b.x;
        });

        //считаем КПД по Q
        this.eff = this.findInGraph(HQ_arr, this.work_point.x)
        //применяем точки к графику
        this.seriesEff = [
            {
              name: '',
              type: 'scatter',
              data: []
            },
            {
              name: 'Line',
              type: 'line',
              data: HQ_arr
            }
        ];


        //дорисовываем остальные графики
        let all_pumps = parseInt(this.user_query.pumps_count_basic.toString()) + parseInt(this.user_query.pumps_count_spare.toString())
          for(let i=2;i<=all_pumps; i++){
            let temp_points = new Array(0);
            HQ_arr.forEach((val: any, index: any) => {
              temp_points.push({x: index == 0 ? val.x : val.x*i, y:val.y})
              x_max = Math.max(val.x*i, x_max);
            })

            if(i>this.user_query.pumps_count_basic){
              this.chartOptionsEff.stroke.colors[i] = '#FEBB3B'
            }

            if(i==this.user_query.pumps_count_basic){
              this.eff = this.findInGraph(temp_points, this.work_point.x)
            }

            this.seriesEff.push({
              name: 'Line'+i,
              type: 'line',
              data: temp_points
            })
          }
        }
    },
    drawPower(){
      let tmp_pump = (this.pump as any);
      if(tmp_pump.UF_POWER_P_JSON && tmp_pump.UF_POWER_P_JSON.length > 0){
        let HQ_obj = JSON.parse(tmp_pump.UF_POWER_P_JSON);//получаем известные точки
        let HQ_arr = [] as any;

        let x_min = 99999;
        let x_max = 0;
        Object.entries(HQ_obj).forEach(([key, value]) => {//делаем массив известных точек
          HQ_arr.push({x:parseFloat(key),y:parseFloat(value as any)})
          x_min = Math.min(parseFloat(key as any), x_min)//максимум
          x_max = Math.max(parseFloat(key as any), x_max)//минимум
        });
        if(this.work_point.x){
          x_min = Math.min(this.work_point.x, x_min)
          x_max = Math.max(this.work_point.x, x_max)
        }


        //сортируем точки
        HQ_arr.sort(function(a:any, b:any) {
          return a.x - b.x;
        });

        //считаем Мощность по Q
        this.power = this.findInGraph(HQ_arr, this.work_point.x)

        //HQ_arr.reverse()
        //console.log(HQ_arr)
        //this.chartOptionsPower.xaxis.min = x_min;
        //this.chartOptionsPower.xaxis.max = x_max;
        //применяем точки к графику
        this.seriesPower = [
            {
              name: '',
              type: 'scatter',
              data: []
            },
            {
              name: 'Line',
              type: 'line',
              data: HQ_arr
            }
          ];
      }
    },
    drawNPSH(){
      let tmp_pump = (this.pump as any);
      if(tmp_pump.UF_NPSH_JSON && tmp_pump.UF_NPSH_JSON.length > 0){
        let HQ_obj = JSON.parse(tmp_pump.UF_NPSH_JSON);//получаем известные точки
        let HQ_arr = [] as any;

        let x_min = 99999;
        let x_max = 0;
        Object.entries(HQ_obj).forEach(([key, value]) => {//делаем массив известных точек
          HQ_arr.push({x:parseFloat(key),y:parseFloat(value as any)})
          x_min = Math.min(parseFloat(key as any), x_min)//максимум
          x_max = Math.max(parseFloat(key as any), x_max)//минимум
        });
        if(this.work_point.x){
          x_min = Math.min(this.work_point.x, x_min)
          x_max = Math.max(this.work_point.x, x_max)
        }


        //сортируем точки
        HQ_arr.sort(function(a:any, b:any) {
          return a.x - b.x;
        });

        this.npshr = this.findInGraph(HQ_arr, this.work_point.x)

        //применяем точки к графику
        this.seriesNPSH = [
            {
              name: '',
              type: 'scatter',
              data: []
            },
            {
              name: 'Line',
              type: 'line',
              data: HQ_arr
            }
          ];

        //дорисовываем остальные графики
        let all_pumps = parseInt(this.user_query.pumps_count_basic.toString()) + parseInt(this.user_query.pumps_count_spare.toString())
          for(let i=2;i<=all_pumps; i++){
            let temp_points = new Array(0);
            HQ_arr.forEach((val: any, index: any) => {
              temp_points.push({x: index == 0 ? val.x : val.x*i, y:val.y})
              x_max = Math.max(val.x*i, x_max);
            })

            if(i>this.user_query.pumps_count_basic){
              this.chartOptionsNPSH.stroke.colors[i] = '#FEBB3B'
            }

            if(i==this.user_query.pumps_count_basic){
              this.npshr = this.findInGraph(temp_points, this.work_point.x)
            }

            this.seriesNPSH.push({
              name: 'Line'+i,
              type: 'line',
              data: temp_points
            })
          }
        
      }
    },

    async loadPump(code: any){
      this.loading = true;
      try{
        const data = await axios.get('https://adl-podbor.rplp.ru/api/', {
          params: {
            type: "Units",
            cmd: "Unit",
            code: code,
            reg_type: this.user_query.reg_type,
            system_type: this.user_query.system_type,
            pumps_count: (Number(this.user_query.pumps_count_basic)+Number(this.user_query.pumps_count_spare))
          }
        });
        //console.log(data.data)
        if(data.data && data.data.data){
          //if(data.data.data.img && data.data.data.img.length > 0) this.img = data.data.data.img
          if(data.data.data.props && data.data.data.props.length > 0) this.characteristics = data.data.data.props
          if(data.data.data.body && data.data.data.body.table && data.data.data.body.table.length > 0) this.body_size = data.data.data.body.table

          if(data.data.data.os && data.data.data.os.length > 0) this.os = data.data.data.os
          if(data.data.data.es && data.data.data.es.length > 0) this.es = data.data.data.es
          if(data.data.data.pv && data.data.data.pv.length > 0) this.img = data.data.data.pv
          if(data.data.data.ot && data.data.data.ot.length > 0) this.ot = data.data.data.ot
          if(data.data.data.pdf && data.data.data.pdf.length > 0) this.pdf_url = data.data.data.pdf
        }
      } catch(e: any){
        console.log(e)
      }
      this.loading = false;
    },
    //функция для определения показаний рабочей точки на графике
    findInGraph(points: any, Q: any){
      //копируем массив
      let points_arr = points.filter((elem:any)=> true);
      let wp = {x:Q}
      //добавляем точку и сортируем
      points_arr.push(wp)
      points_arr.sort(function(a:any, b:any) {
          return a.x - b.x;
      });
      //ищем позицию
      let point_index = 0;
      point_index = points_arr.findIndex((x:any) => x.x === Q && !x.y);
      //получаем соседние точки, если они существуют
      if(points_arr[point_index-1] && points_arr[point_index+1]){
        //строим между точками прямую и считаем где находится точка которую надо найти
        let diff_x = points_arr[point_index+1].x - points_arr[point_index-1].x;
        let diff_y = points_arr[point_index+1].y - points_arr[point_index-1].y;

        let point_ratio = (wp.x - points_arr[point_index-1].x)/diff_x;
        let point_Y = points_arr[point_index-1].y + diff_y*point_ratio;

        return point_Y;
      } else {
        return 0;
      }
    },

    createPDF(){
      if(document.getElementById('graphform')) { (document.getElementById('graphform') as HTMLFormElement)?.submit(); }
    }
  },
  mounted() {
      console.log(this.pump)
      let tmp_pump = (this.pump as any);
      if(tmp_pump.UF_CODE) this.loadPump(tmp_pump.UF_CODE)
      this.drawHQ();
      this.drawEff();
      this.drawPower();
      this.drawNPSH()

      setTimeout( ()=>{
        let svg_1 = document.getElementById('apexchartsline-1')?.getElementsByClassName('apexcharts-svg')[0]?.outerHTML;
        let svg_2 = document.getElementById('apexchartsline-2')?.getElementsByClassName('apexcharts-svg')[0]?.outerHTML;
        let svg_3 = document.getElementById('apexchartsline-4')?.getElementsByClassName('apexcharts-svg')[0]?.outerHTML;

        if(svg_1 && document.getElementById('input_graph')) (document.getElementById('input_graph') as HTMLInputElement).value = encodeURIComponent(svg_1?.toString());
        if(svg_2 && document.getElementById('input_graph1')) (document.getElementById('input_graph1') as HTMLInputElement).value = encodeURIComponent(svg_2?.toString());
        if(svg_3 && document.getElementById('input_graph2')) (document.getElementById('input_graph2') as HTMLInputElement).value = encodeURIComponent(svg_3?.toString());

        //название
        let unit_name:any = this.pump;
        if(unit_name.UF_MODEL){
          (document.getElementById('unit_name') as HTMLInputElement).value = encodeURIComponent(unit_name.UF_MODEL?.toString());
        }

        //Общие технологические данные
        if(this.ot.length > 0){
          (document.getElementById('tech_data') as HTMLInputElement).value = encodeURIComponent(this.ot?.toString());
        }

        //Общие данные по шкафам управления
        if(this.os.length > 0){
          (document.getElementById('ctrl_box_data') as HTMLInputElement).value = encodeURIComponent(this.os?.toString());
        }
        //входные данные
        if(document.getElementById('input_container')){
          let input_data = (document.getElementById('input_container') as HTMLElement)?.innerHTML;
          (document.getElementById('input_data') as HTMLInputElement).value = encodeURIComponent(input_data?.toString());
        }

        this.svgready = true;
      }, 1000);
  },
})
</script>
<style scoped>
.left_info {
  border-right: 2px var(--root-light) solid;
}
.scroll_block {
    max-height: calc(100vh - 145px) !important;
    overflow: auto;
    overflow-x: hidden;
    min-height: calc(100vh - 145px) !important;
}
#synced-charts {
  width: 1024px;
  max-width: 100%;
}
.mnull{
  margin-left: 0px;
  margin-right: 0px;
}
.left_info_container {
  max-height: calc(100vh - 64px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.left_info_container > .pump_img {
  flex: 1 1 auto;
}
.left_info_container .buttons {
  padding: 15px;
  margin-bottom: 30px;
}

#test_canvas{
  width: 1024px;
  height: 250px;
}
</style>
<style>
.vue-apexcharts ~ .vue-apexcharts .apexcharts-tooltip, .vue-apexcharts ~ .vue-apexcharts .apexcharts-xaxistooltip {
  display: none;
}
.pump_img img{
  object-position: top;
}
.tmp_image, #test_canvas {
  position: absolute;
  left: -9990px;
  top: 0px;
  width: 1024px !important;
  height: 250px !important;
  object-fit: contain;
}
.line_colors_map {
  display: flex;
  justify-content: center;
  width: 1000px;
  max-width: 100%;
}
.line_colors_map span {
  margin: 5px 5px;
}
.line_colors_map span::before{
  content: '';
  width: 25px;
  height: 5px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
  margin-right: 3px;
}
.line_colors_map span:nth-child(1)::before{
  background: #26E7A5;
}
.line_colors_map span:nth-child(2)::before{
  background: #FEBB3B;
}
.line_colors_map span:nth-child(3)::before{
  background: #FF6077;
}
.line_colors_map span:nth-child(4)::before{
  background: #259efa;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: -3px;
}
.white_links a{
  color: #fff;
}
.text_block ul {
  padding-left: 20px;
}
</style>