<template>
    <v-row class="h-100" no-gutters>
        <v-col md="3">
            <div class="left_info h-100">
              <div class="left_info_container">
                <div id="input_container">
                  <v-table density="compact">
                    <thead>
                      <tr>
                        <th class="text-left" colspan="2">
                          Данные запроса
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="user_query.name && user_query.name.length > 0">
                        <td>Наименование</td><td>{{ user_query.name }}</td>
                      </tr>
                      <tr v-if="user_query.art && user_query.art.length > 0">
                        <td>Артикул</td><td>{{ user_query.art }}</td>
                      </tr>
                      <tr v-if="user_query.pumpType && user_query.pumpType.length > 0">
                        <td>Серия</td><td>{{ user_query.pumpType }}</td>
                      </tr>
                      <tr v-if="user_query.vid && user_query.vid.length > 0">
                        <td>Тип насоса</td><td>{{ user_query.vid }}</td>
                      </tr>
                      <tr v-if="user_query.systemType && user_query.systemType.length > 0">
                        <td>Тип системы</td><td>{{ user_query.systemType }}</td>
                      </tr>
                      <tr>
                        <td>Тип жидкости</td><td>Чистая вода</td>
                      </tr>
                      <tr v-if="user_query.Q > 0 || user_query.H > 0">
                        <td>Работая точка</td><td>Q: {{ user_query.Q }}м<sup>3</sup>/ч, H: {{ user_query.H }}м</td>
                      </tr>
                    </tbody>
                  </v-table>
                  <v-table density="compact" v-if="wp_in_gr && user_query.Q && user_query.H && (user_query.Q > 0 || user_query.H > 0)">
                    <thead>
                      <tr>
                        <th class="text-left" colspan="2">
                          Данные гидравлики <span class="text-muted">(раб. точка)</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Расход</td><td>{{ user_query.Q }}м<sup>3</sup>/ч</td>
                      </tr>
                      <tr>
                        <td>Напор</td><td>{{head_h.toFixed(2)}}м</td>
                      </tr>
                      <tr>
                        <td>КПД</td><td>{{eff.toFixed(2)}}%</td>
                      </tr>
                      <tr>
                        <td>Мощность</td><td>{{power.toFixed(2)}}кВт</td>
                      </tr>
                      <tr>
                        <td>NPSHr</td><td>{{npshr.toFixed(2)}}м</td>
                      </tr>
                    </tbody>
                  </v-table>
                </div>
                <v-img class="pump_img" v-if="img.length > 0" width="100%" contain :src="img">
                    <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                            <v-progress-circular
                            color="grey-lighten-4"
                            indeterminate
                            ></v-progress-circular>
                        </div>
                    </template>
                </v-img>
                <div class="buttons">
                  <v-btn v-if="svgready" @click="createPDF" block color="primary" prepend-icon="mdi-file-pdf-box">Скачать PDF</v-btn>
                  <form id="graphform" :action="pdf_url" method="post" target="_blank">
                    <input type="hidden" name="graph" id="input_graph">
                    <input type="hidden" name="graph1" id="input_graph1">
                    <input type="hidden" name="graph2" id="input_graph2">
                    <input type="hidden" name="graph3" id="input_graph3">

                    <input type="hidden" name="input_data" id="input_data">
                  </form>
                </div>
              </div>
            </div>
        </v-col>
        <v-col md="9">
            <v-tabs v-model="tab" bg-color="var(--root-light)" color="var(--root-color)" >
                <v-tab value="one">Графики</v-tab>
                <v-tab value="four">Описание</v-tab>
                <v-tab value="two">Общие технические данные</v-tab>
                <v-tab value="three">Размеры</v-tab>
                <v-tab value="price_tab">УЗНАТЬ ЦЕНУ</v-tab>
                
            </v-tabs>
            <v-card-text >
                <v-tabs-window v-model="tab">
                    <v-tabs-window-item value="one" class=" h-100">
                        <div class="scroll_block h-100">
                            <v-row class="mnull">
                              <v-col md="12">
                                <div id="synced-charts">
                                    <apexchart  type="line" height="250" :options="chartOptions" :series="series"></apexchart>
                                    <apexchart type="line" height="250" :options="chartOptionsEff" :series="seriesEff"></apexchart>
                                    <apexchart type="line" height="250" :options="chartOptionsPower" :series="seriesPower"></apexchart>
                                    <apexchart type="line" height="250" :options="chartOptionsNPSH" :series="seriesNPSH"></apexchart>
                                </div>
                              </v-col>
                              <v-col md="12">
                                <v-alert title="Внимание!" type="info">Поставщик может только рекомендовать заказчику оборудования с требуемыми характеристиками. <br><br>Ответственность за окончательный выбор оборудования лежит на Заказчике, поскольку даже после тщательного анализа опросных листов и получения дополнительной информации для Поставщика всегда остается неопределенность в условиях эксплуатации оборудования. При этом Заказчик несет ответственность за достоверность предоставленной информации.</v-alert>
                              </v-col>
                            </v-row>
                            
                        </div>
                    </v-tabs-window-item>

                    <v-tabs-window-item value="two" class="h-100">
                        <v-table>
                            <thead>
                            <tr>
                                <th class="text-left">
                                Характеристика
                                </th>
                                <th class="text-left">
                                Значение
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="item in characteristics"
                                :key="item.name"
                            >
                                <td>{{ item.name }}</td>
                                <td>{{ item.val }}</td>
                            </tr>
                            </tbody>
                        </v-table>
                    </v-tabs-window-item>

                    <v-tabs-window-item value="three" class="h-100">
                      <v-row>
                        <v-col md="6">
                          <v-table density="compact">
                            <thead>
                              <tr>
                                  <th class="text-left">
                                  Характеристика
                                  </th>
                                  <th class="text-left">
                                  Значение
                                  </th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr
                                  v-for="item in body_size"
                                  :key="item.name"
                              >
                                  <td>{{ item.name }}</td>
                                  <td>{{ item.val }}</td>
                              </tr>
                              </tbody>
                          </v-table>
                        </v-col>
                        <v-col class="v-col-12" md="6">
                          <v-img class="dimensions_img" v-if="dimensions_img.length > 0" width="100%" contain :src="dimensions_img">
                              <template v-slot:placeholder>
                                  <div class="d-flex align-center justify-center fill-height">
                                      <v-progress-circular
                                      color="grey-lighten-4"
                                      indeterminate
                                      ></v-progress-circular>
                                  </div>
                              </template>
                          </v-img>
                        </v-col>
                      </v-row>
                      
                    </v-tabs-window-item>
                    <v-tabs-window-item value="four" class="h-100">
                        <div class="marking_container">
                          <h3>Маркировка</h3>
                          <div class="marking_flex">
                            <div class="marking_item" v-for="mark in marking" :key="mark.i">
                              <div class="m_cont">
                                <span>{{ mark.val }}</span>
                                <p>{{ mark.i }}</p>
                              </div>
                              <div>
                                {{ mark.d }}
                              </div>
                            </div>
                          </div>
                          <div class="marking_d">
                            <div class="marking_inline_item" v-for="mark in marking" :key="mark.i">
                              <span>{{ mark.i }}</span>
                              <span>{{ mark.val }}</span>
                              <span>{{ mark.name }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="descr_cont">
                          <div v-html="html_description"></div>
                        </div>
                        <div v-if="gallery.length > 0" class="gallery_imgs">
                          <h2>Изображения</h2>
                          <v-row>
                            <v-col md="4" v-for="image in gallery" :key="image">
                              <v-img class="ga_img" v-if="image.length > 0" width="100%" contain :src="image">
                                  <template v-slot:placeholder>
                                      <div class="d-flex align-center justify-center fill-height">
                                          <v-progress-circular
                                          color="grey-lighten-4"
                                          indeterminate
                                          ></v-progress-circular>
                                      </div>
                                  </template>
                              </v-img>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-if="tables.length > 0" class="descr_tables">
                          <h2>Таблицы</h2>
                          <div v-html="tables"></div>
                        </div>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="price_tab" class="h-100">
                      <v-alert title="" type="info" class="not_found_alert">Для уточнения цены, просим направить сформированное ТКП или заполненные <a href="https://adl.ru/upload/adl-doc/NO_podbor_nasosa.doc" target="_blank">Опросные листы</a> и отправить на почту <a href="mailto:info@adl.ru">info@adl.ru</a> с указанием контактных данных организаций, а также наименованием и адресом объекта, на которое подбирается оборудование.</v-alert>
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-card-text>
        </v-col>
    </v-row>
    <canvas id="test_canvas" ref="canvas"></canvas>
</template>
<script lang='ts'>
import { defineComponent } from 'vue'
import axios from "axios";
//import { jsPDF } from "jspdf";

export default defineComponent({
  components: {
  },
  props: {
    pump:{
      default: new Object()
    },
    work_point:{
      default: {
        x: -1,
        y: -1
      }
    },
    user_query: {
      default: {
        Q: 0,
        H: 0,
        name: '',
        art: '',
        pumpType: '',
        vid: '',
        systemType: ''
      }
    }
  },
  data () {
    return {
      loading: false,
      img: '',
      tab: null,
      characteristics:new Array(0),
      body_size:new Array(0),
      marking:new Array(0),
      html_description: '',
      dimensions_img: '',
      gallery:new Array(0),
      tables:'',
      //данные гидравлики
      head_h: 0,
      eff: 0,
      power: 0,
      npshr: 0,
      //попадает ли рабочая точка на график
      wp_in_gr: true,
      //массив с графиками в base64
      png_images: new Array(0),
      //url для pdf
      pdf_url:'',
      //строки с готовыми svg
      grstr0:'',
      grstr1:'',
      grstr2:'',
      grstr3:'',
      svgready: false,
      //график с рабочей точкой
      series: [
        {
          name: 'Рабочая точка',
          type: 'scatter',
          data: [{
            x: 0,
            y: 0
          }]
        },
        {
          name: 'Line',
          type: 'line',
          data: []
        }
      ],
      chartOptions: {
        chart: {
          height: 250,
          type: 'line',
          id: 'line-1',
          //group: 'graph',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        fill: {
          type:'solid',
        },
        markers: {
          size: [6, 0]
        },
        tooltip: {
          //shared: true,
          shared: false,
          x: {
            show: false
          },
          intersect: true,
          //inverseOrder:true
        },
        stroke: {
          width: 2,
          curve: 'smooth'
          //curve: 'straight'
        },
        legend: {
          show: false
        },
        xaxis: {
          type: 'numeric',
          min: 0,
          max: 1,
          tickAmount: 10,
          title: {
            text: 'Расход [м³/ч]'
          },
          axisTicks: {
              show: true,
              borderType: 'solid',
              //color: '#78909C',
              height: "100%",
              offsetX: 0,
              offsetY: 0
          },
        },
        yaxis: {
          title: {
            text: 'Напор [м]'
          },
          min: 0,
          //tickAmount: 10
        }
    },


        //график КПД
        seriesEff: [
            {
              name: 'Line',
              type: 'line',
              data: []
            }
        ],
        chartOptionsEff: {
            chart: {
              id: 'line-2',
              //group: 'graph',
              height: 250,
              type: 'line',
              zoom: {
                enabled: false
              },
              toolbar: {
                show: false
              },
              /*events: {
                mounted: function(ctx: any, config: any) {
                  console.log(ctx)
                  console.log(config)
                },
                updated:function(ctx: any, config: any) {
                  console.log(ctx)
                  console.log(config)
                },
              }*/
            },
            dataLabels: {
              enabled: false
            },
            fill: {
              type:'solid',
            },
            markers: {
              size: [6, 0]
            },
            tooltip: {
              shared: false,
              intersect: true,
              //enabled: false,
              //shared: true,
              //intersect: true,
            },
            stroke: {
              width: 2,
              curve: 'smooth'
            },
            legend: {
              show: false
            },
            xaxis: {
              type: 'numeric',
              min: 0,
              max: 1,
              tickAmount: 10,
              title: {
                text: 'Расход [м³/ч]'
              },
              axisTicks: {
                  show: true,
                  borderType: 'solid',
                  height: "100%",
                  offsetX: 0,
                  offsetY: 0
              },
            },
            yaxis: {
              title: {
                text: 'КПД [%]'
              },
              min: 0,
            }
        },

        //график Мощности
        seriesPower: [
            {
              name: 'Line',
              type: 'line',
              data: []
            }
        ],
        chartOptionsPower: {
            chart: {
              id: 'line-3',
              //group: 'graph',
              height: 250,
              type: 'line',
              zoom: {
                enabled: false
              },
              toolbar: {
                show: false
              }
            },
            fill: {
              type:'solid',
            },
            markers: {
              size: [6, 0]
            },
            tooltip: {
              shared: false,
              intersect: true,
            },
            stroke: {
              width: 2,
              curve: 'smooth'
            },
            legend: {
              show: false
            },
            xaxis: {
              type: 'numeric',
              min: 0,
              max: 1,
              tickAmount: 10,
              title: {
                text: 'Расход [м³/ч]'
              },
              axisTicks: {
                  show: true,
                  borderType: 'solid',
                  height: "100%",
                  offsetX: 0,
                  offsetY: 0
              },
            },
            yaxis: {
              title: {
                text: 'Мощность [кВт]'
              },
              min: 0,
            }
        },

        //график КПД NPSH
        seriesNPSH: [
            {
              name: 'Line',
              type: 'line',
              data: []
            }
        ],
        chartOptionsNPSH: {
            chart: {
              id: 'line-4',
              //group: 'graph',
              height: 250,
              type: 'line',
              zoom: {
                enabled: false
              },
              toolbar: {
                show: false
              }
            },
            fill: {
              type:'solid',
            },
            markers: {
              size: [6, 0]
            },
            tooltip: {
              shared: false,
              intersect: true,
            },
            stroke: {
              width: 2,
              curve: 'smooth'
            },
            legend: {
              show: false
            },
            xaxis: {
              type: 'numeric',
              min: 0,
              max: 1,
              tickAmount: 10,
              title: {
                text: 'Расход [м³/ч]'
              },
              axisTicks: {
                  show: true,
                  borderType: 'solid',
                  height: "100%",
                  offsetX: 0,
                  offsetY: 0
              },
            },
            yaxis: {
              title: {
                text: 'NPSHr [м]'
              },
              min: 0,
            }
        },
    }
  },
  methods: {
    drawHQ(){

      
      //строим график напора и высоты
      let tmp_pump = (this.pump as any);

      console.log(tmp_pump)

      if(tmp_pump.UF_HEAD_H_JSON && tmp_pump.UF_HEAD_H_JSON.length > 0){
        let HQ_obj = JSON.parse(tmp_pump.UF_HEAD_H_JSON);//получаем известные точки
        let HQ_arr = [] as any;

        let x_min = 99999;
        let x_max = 0;
        Object.entries(HQ_obj).forEach(([key, value]) => {//делаем массив известных точек
          HQ_arr.push({x:parseFloat(key),y:parseFloat(value as any)})
          x_min = Math.min(parseFloat(key as any), x_min)//максимум
          x_max = Math.max(parseFloat(key as any), x_max)//минимум
        });
        if(this.work_point.x){
          x_min = Math.min(this.work_point.x, x_min)
          x_max = Math.max(this.work_point.x, x_max)
        }
        //console.log(this.work_point.x)
        //добавляем точки по формуле
        /*for(let Q = x_min; Q < x_max; Q += 1){
          let H = parseFloat(tmp_pump.UF_H0) + parseFloat(tmp_pump.UF_S1)*Q + parseFloat(tmp_pump.UF_S2)*(Q*Q)
          HQ_arr.push({y:H,x:Q})
        }*/

        //проверяем попадает ли точка на график
        this.wp_in_gr = !(x_min == this.work_point.x || x_max == this.work_point.x);

        //данные гидравлики для напора
        let Q = this.work_point.x;
        //не подходит для некоторых графиков
        this.head_h = parseFloat(tmp_pump.UF_H0) + parseFloat(tmp_pump.UF_S1)*Q + parseFloat(tmp_pump.UF_S2)*(Q*Q)
        //this.head_h = this.findInGraph(HQ_arr, this.work_point.x)


        //сортируем точки
        HQ_arr.sort(function(a:any, b:any) {
          return a.x - b.x;
        });

        //HQ_arr.reverse()
        //console.log(x_min)
        //this.chartOptions.xaxis.min = x_min;

        //Правка, все графики начинаются с 0
        x_min = 0;


        this.chartOptions.xaxis.min = x_min;
        this.chartOptions.xaxis.max = x_max;

        this.chartOptionsEff.xaxis.min = x_min;
        this.chartOptionsEff.xaxis.max = x_max;

        this.chartOptionsPower.xaxis.min = x_min;
        this.chartOptionsPower.xaxis.max = x_max;

        this.chartOptionsNPSH.xaxis.min = x_min;
        this.chartOptionsNPSH.xaxis.max = x_max;


        //применяем точки к графику
        this.series = [
            {
              name: 'Рабочая точка',
              type: 'scatter',
              data: [this.work_point]//рабочая точка
            },
            {
              name: 'Line',
              type: 'line',
              data: HQ_arr
            }
          ];
      }
    },
    drawEff(){
      let tmp_pump = (this.pump as any);
      if(tmp_pump.UF_EFF_JSON && tmp_pump.UF_EFF_JSON.length > 0){
        let HQ_obj = JSON.parse(tmp_pump.UF_EFF_JSON);//получаем известные точки
        let HQ_arr = [] as any;

        let x_min = 99999;
        let x_max = 0;
        Object.entries(HQ_obj).forEach(([key, value]) => {//делаем массив известных точек
          HQ_arr.push({x:parseFloat(key),y:parseFloat(value as any)})
          x_min = Math.min(parseFloat(key as any), x_min)//максимум
          x_max = Math.max(parseFloat(key as any), x_max)//минимум
        });
        /*if(this.work_point.x){
          x_min = Math.min(this.work_point.x, x_min)
          x_max = Math.max(this.work_point.x, x_max)
        }*/


        //сортируем точки
        HQ_arr.sort(function(a:any, b:any) {
          return a.x - b.x;
        });

        //считаем КПД по Q
        this.eff = this.findInGraph(HQ_arr, this.work_point.x)

        //HQ_arr.reverse()
        //console.log(HQ_arr)
        //this.chartOptionsEff.xaxis.min = x_min;
        //this.chartOptionsEff.xaxis.max = x_max;
        //применяем точки к графику
        this.seriesEff = [
            {
              name: '',
              type: 'scatter',
              data: []
            },
            {
              name: 'Line',
              type: 'line',
              data: HQ_arr
            }
          ];
      }
    },
    drawPower(){
      let tmp_pump = (this.pump as any);
      if(tmp_pump.UF_POWER_P_JSON && tmp_pump.UF_POWER_P_JSON.length > 0){
        let HQ_obj = JSON.parse(tmp_pump.UF_POWER_P_JSON);//получаем известные точки
        let HQ_arr = [] as any;

        let x_min = 99999;
        let x_max = 0;
        Object.entries(HQ_obj).forEach(([key, value]) => {//делаем массив известных точек
          HQ_arr.push({x:parseFloat(key),y:parseFloat(value as any)})
          x_min = Math.min(parseFloat(key as any), x_min)//максимум
          x_max = Math.max(parseFloat(key as any), x_max)//минимум
        });
        /*if(this.work_point.x){
          x_min = Math.min(this.work_point.x, x_min)
          x_max = Math.max(this.work_point.x, x_max)
        }*/


        //сортируем точки
        HQ_arr.sort(function(a:any, b:any) {
          return a.x - b.x;
        });

        //считаем Мощность по Q
        this.power = this.findInGraph(HQ_arr, this.work_point.x)

        //HQ_arr.reverse()
        //console.log(HQ_arr)
        //this.chartOptionsPower.xaxis.min = x_min;
        //this.chartOptionsPower.xaxis.max = x_max;
        //применяем точки к графику
        this.seriesPower = [
            {
              name: '',
              type: 'scatter',
              data: []
            },
            {
              name: 'Line',
              type: 'line',
              data: HQ_arr
            }
          ];
      }
    },
    drawNPSH(){
      let tmp_pump = (this.pump as any);
      if(tmp_pump.UF_NPSH_JSON && tmp_pump.UF_NPSH_JSON.length > 0){
        let HQ_obj = JSON.parse(tmp_pump.UF_NPSH_JSON);//получаем известные точки
        let HQ_arr = [] as any;

        let x_min = 99999;
        let x_max = 0;
        Object.entries(HQ_obj).forEach(([key, value]) => {//делаем массив известных точек
          HQ_arr.push({x:parseFloat(key),y:parseFloat(value as any)})
          x_min = Math.min(parseFloat(key as any), x_min)//максимум
          x_max = Math.max(parseFloat(key as any), x_max)//минимум
        });
        /*if(this.work_point.x){
          x_min = Math.min(this.work_point.x, x_min)
          x_max = Math.max(this.work_point.x, x_max)
        }*/


        //сортируем точки
        HQ_arr.sort(function(a:any, b:any) {
          return a.x - b.x;
        });

        this.npshr = this.findInGraph(HQ_arr, this.work_point.x)

        //HQ_arr.reverse()
        //console.log(HQ_arr)
        //this.chartOptionsNPSH.xaxis.min = x_min;
        //this.chartOptionsNPSH.xaxis.max = x_max;
        //применяем точки к графику
        this.seriesNPSH = [
            {
              name: '',
              type: 'scatter',
              data: []
            },
            {
              name: 'Line',
              type: 'line',
              data: HQ_arr
            }
          ];
      }
    },

    async loadPump(code: any){
      this.loading = true;
      try{
        const data = await axios.get('https://adl-podbor.rplp.ru/api/', {
          params: {
            type: "Pumps",
            cmd: "Pump",
            code: code
          }
        });
        console.log(data.data)
        if(data.data && data.data.data){
          if(data.data.data.img && data.data.data.img.length > 0) this.img = data.data.data.img
          if(data.data.data.props && data.data.data.props.length > 0) this.characteristics = data.data.data.props
          if(data.data.data.body && data.data.data.body.table && data.data.data.body.table.length > 0) this.body_size = data.data.data.body.table
          if(data.data.data.marking && data.data.data.marking.length > 0) this.marking = data.data.data.marking
          if(data.data.data.description && data.data.data.description.length > 0) this.html_description = data.data.data.description
          if(data.data.data.img_d && data.data.data.img_d.length > 0) this.dimensions_img = data.data.data.img_d
          if(data.data.data.gallery && data.data.data.gallery.length > 0) this.gallery = data.data.data.gallery
          if(data.data.data.tables && data.data.data.tables.length > 0) this.tables = data.data.data.tables
          if(data.data.data.pdf && data.data.data.pdf.length > 0) this.pdf_url = data.data.data.pdf
        }
      } catch(e: any){
        console.log(e)
      }
      this.loading = false;
    },
    //функция для определения показаний рабочей точки на графике
    findInGraph(points: any, Q: any){
      //копируем массив
      let points_arr = points.filter((elem:any)=> true);
      let wp = {x:Q}
      //добавляем точку и сортируем
      points_arr.push(wp)
      points_arr.sort(function(a:any, b:any) {
          return a.x - b.x;
      });
      //ищем позицию
      let point_index = 0;
      point_index = points_arr.findIndex((x:any) => x.x === Q && !x.y);
      //получаем соседние точки, если они существуют
      if(points_arr[point_index-1] && points_arr[point_index+1]){
        //строим между точками прямую и считаем где находится точка которую надо найти
        let diff_x = points_arr[point_index+1].x - points_arr[point_index-1].x;
        let diff_y = points_arr[point_index+1].y - points_arr[point_index-1].y;

        let point_ratio = (wp.x - points_arr[point_index-1].x)/diff_x;
        let point_Y = points_arr[point_index-1].y + diff_y*point_ratio;

        return point_Y;
      } else {
        return 0;
      }
    },

    createPDF(){
      /*let svg_1 = document.getElementById('apexchartsline-1')?.getElementsByClassName('apexcharts-svg')[0]?.outerHTML;
      let svg_2 = document.getElementById('apexchartsline-2')?.getElementsByClassName('apexcharts-svg')[0]?.outerHTML;
      let svg_3 = document.getElementById('apexchartsline-3')?.getElementsByClassName('apexcharts-svg')[0]?.outerHTML;
      let svg_4 = document.getElementById('apexchartsline-4')?.getElementsByClassName('apexcharts-svg')[0]?.outerHTML;

      if(svg_1 && document.getElementById('input_graph')) (document.getElementById('input_graph') as HTMLInputElement).value = encodeURIComponent(svg_1?.toString());
      if(svg_2 && document.getElementById('input_graph1')) (document.getElementById('input_graph1') as HTMLInputElement).value = encodeURIComponent(svg_2?.toString());
      if(svg_3 && document.getElementById('input_graph2')) (document.getElementById('input_graph2') as HTMLInputElement).value = encodeURIComponent(svg_3?.toString());
      if(svg_4 && document.getElementById('input_graph3')) (document.getElementById('input_graph3') as HTMLInputElement).value = encodeURIComponent(svg_4?.toString());*/

      if(document.getElementById('graphform')) { (document.getElementById('graphform') as HTMLFormElement)?.submit(); }
    }

  },
  mounted() {
      //console.log(this.pump)
      let tmp_pump = (this.pump as any);
      if(tmp_pump.UF_CODE) this.loadPump(tmp_pump.UF_CODE)

      console.log(tmp_pump)
      this.drawHQ();
      this.drawEff();
      this.drawPower();
      this.drawNPSH()

      setTimeout( ()=>{
        let svg_1 = document.getElementById('apexchartsline-1')?.getElementsByClassName('apexcharts-svg')[0]?.outerHTML;
        let svg_2 = document.getElementById('apexchartsline-2')?.getElementsByClassName('apexcharts-svg')[0]?.outerHTML;
        let svg_3 = document.getElementById('apexchartsline-3')?.getElementsByClassName('apexcharts-svg')[0]?.outerHTML;
        let svg_4 = document.getElementById('apexchartsline-4')?.getElementsByClassName('apexcharts-svg')[0]?.outerHTML;

        if(svg_1 && document.getElementById('input_graph')) (document.getElementById('input_graph') as HTMLInputElement).value = encodeURIComponent(svg_1?.toString());
        if(svg_2 && document.getElementById('input_graph1')) (document.getElementById('input_graph1') as HTMLInputElement).value = encodeURIComponent(svg_2?.toString());
        if(svg_3 && document.getElementById('input_graph2')) (document.getElementById('input_graph2') as HTMLInputElement).value = encodeURIComponent(svg_3?.toString());
        if(svg_4 && document.getElementById('input_graph3')) (document.getElementById('input_graph3') as HTMLInputElement).value = encodeURIComponent(svg_4?.toString());

        if(document.getElementById('input_container')){
          let input_data = (document.getElementById('input_container') as HTMLElement)?.innerHTML;
          (document.getElementById('input_data') as HTMLInputElement).value = encodeURIComponent(input_data?.toString());
        }

        this.svgready = true;
      }, 1000);
  },
})
</script>
<style scoped>
.left_info {
  border-right: 2px var(--root-light) solid;
}
.scroll_block {
    max-height: calc(100vh - 145px) !important;
    overflow: auto;
    overflow-x: hidden;
    min-height: calc(100vh - 145px) !important;
}
#synced-charts {
  width: 1024px;
  max-width: 100%;
}
.mnull{
  margin-left: 0px;
  margin-right: 0px;
}
.left_info_container {
  max-height: calc(100vh - 64px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.left_info_container > .pump_img {
  flex: 1 1 auto;
}
.left_info_container .buttons {
  padding: 15px;
  margin-bottom: 30px;
}

#test_canvas{
  width: 1024px;
  height: 250px;
}

.marking_flex {
  display: flex;
}
.marking_item {
  display: flex;
  align-items: flex-start;
}
.m_cont {
  text-align: center;
}
.m_cont span {
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  padding: 15px;
  background: lightgray;
  border-radius: 15px;
  line-height: 1;
}
.m_cont + div {
  font-size: 30px;
  line-height: 1;
  padding: 9px;
}
.marking_container h3 {
  margin-bottom: 15px;
  font-size: 20px;
}
.marking_d {
  width: 800px;
  /*display: flex;
  flex-wrap: wrap;*/
  margin-top: 15px;
  max-width: 100%;
  columns: 2;
}
.marking_inline_item {
  /*width: 50%;*/
  display: flex;
  margin: 5px 0px;
  font-size: 16px;
  align-items: center;
}
.marking_inline_item > span:nth-child(1) {
  min-width: 20px;
  text-align: center;
}
.marking_inline_item > span:nth-child(2) {
  background: lightgray;
  min-width: 50px;
  text-align: center;
  padding: 5px;
  margin-right: 15px
}
.descr_cont {
  margin-top: 15px;
}
.marking_flex .marking_item:last-child > div:last-child {
  display: none;
}
.dimensions_img{
  max-height: 70vh;
}
</style>
<style>
.vue-apexcharts ~ .vue-apexcharts .apexcharts-tooltip, .vue-apexcharts ~ .vue-apexcharts .apexcharts-xaxistooltip {
  display: none;
}
.pump_img img{
  object-position: top;
}
.tmp_image, #test_canvas {
  position: absolute;
  left: -9990px;
  top: 0px;
  width: 1024px !important;
  height: 250px !important;
  object-fit: contain;
}

</style>