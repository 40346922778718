<template>
  <div class="h-100">
    <v-row class="h-100" justify="center" align="center" no-gutters >
      <v-col xl="78" lg="10" md="12">
        <v-card>
          <v-card-item>
              <v-card-title>
                <div class="flex_title">
                  <h1 class="text-h4">Насосные установки «ГРАНФЛОУ»</h1>
                  <v-btn @click="$router.push('/')" color="var(--root-color)" variant="tonal">назад</v-btn>
                </div>
              </v-card-title>
              <v-card-subtitle>Заполнение параметров</v-card-subtitle>
            </v-card-item>
            <v-card-text>
              <v-row >
                <v-col class="v-col-12" sm="4" md="4" lg="4" xl="3">
                  <v-text-field v-model="pump_name" label="Наименование насосов" variant="outlined" density="compact"></v-text-field>
                  <!--<v-text-field v-model="pump_art" label="Артикул насосов" variant="outlined" density="compact"></v-text-field>-->
                  <v-select v-model="pump_type" clearable label="Тип насоса" :items="pump_types_filtered" variant="outlined" density="compact"></v-select>
                  <v-select v-model="pump_vid" clearable label="Вид насосов" :items="types_filtered" variant="outlined" density="compact"></v-select>
                  <v-select v-model="system_type_f" clearable label="Тип системы" :items="system_type" variant="outlined" density="compact" data-required></v-select>
                  <v-select v-model="reg_type" clearable label="Тип регулирования" :items="reg_types" variant="outlined" density="compact" data-required></v-select>
                  
                  <h3>Диаметр коллектора</h3><br>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field min="0.01" v-model="dn_vsas" label="DNвсас" type="number" variant="outlined" density="compact"></v-text-field>
                    </v-col>
                    <v-col sm="6">
                      <v-text-field min="0.01" v-model="dn_nap" label="DNнап" type="number" variant="outlined" density="compact"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-btn size="small" variant="tonal">Проверить скорость воды
                    <v-icon icon="mdi-menu-down"></v-icon>

                    <v-menu activator="parent" location="bottom start" transition="fade-transition" :close-on-content-click="false">
                      <v-card>
                        <v-card-item>
                          <v-card-title>Проверка скорости воды в трубопроводе</v-card-title>
                          <v-card-subtitle>Заполнение параметров</v-card-subtitle>
                        </v-card-item>
                        <v-card-text>
                          Рекомендуемая скорость воды от 1 до 2 м/с<br>
                          Для ХВС не должно не должно превышать 3 м/с<br>
                          Для пожаротушение не должно превышать 10 м/с<br><br>
                          <v-row>
                            <v-col md="4">
                              <v-text-field min="0.01" step="0.01" v-model="speed_q" label="Q (м3/ч)" type="number" variant="outlined" density="compact" data-required></v-text-field>
                            </v-col>
                            <v-col md="4">
                              <v-text-field min="1" v-model="speed_d" label="D мм" type="number" variant="outlined" density="compact" data-required></v-text-field>
                            </v-col>
                            <v-col md="4">
                              <v-btn @click="calcSpeed()" icon="mdi-calculator" size="small" color="var(--root-color)" variant="tonal">
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-alert v-if="parseFloat(water_speed) > 0" :text="'Скорость воды '+water_speed+'м/с'"></v-alert>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-btn>
                  <br><br>

                  <v-textarea rows="2" label="Доп. Требования по автоматизации" v-model="additional_auto" variant="outlined" auto-grow></v-textarea>
                  <v-textarea rows="2" label="Доп. Требования к комплектации" v-model="additional_comp" variant="outlined" auto-grow></v-textarea>

    

                  <v-switch class="zokei_switch" v-model="t_max_over_70" color="var(--root-color)" label="Tmax рабочей среды, более 70°С"></v-switch>
                  <h3>Давление</h3><br>
                  <v-row >
                    <v-col sm="12">
                      <v-text-field min="0" v-model="p_in" class="pressure" label="Входное, бар" type="number" variant="outlined" density="compact"></v-text-field>
                      <br>
                      <v-text-field min="0" @change="maxWorkP()" v-model="p_max" label="Максимальное рабочее, бар" type="number" variant="outlined" density="compact"></v-text-field>
                    </v-col>
                  </v-row>
                  <h3>Количество насосов</h3><br>
                  <v-row >
                    <v-col sm="6">
                      <v-text-field v-model="pumps_count_basic" min="1" label="Основных" type="number" variant="outlined" density="compact"  data-required></v-text-field>
                    </v-col>
                    <v-col sm="6">
                      <v-text-field v-model="pumps_count_spare" label="Резервных" min="0" type="number" variant="outlined" density="compact" data-required></v-text-field>
                    </v-col>
                  </v-row>
                  <h3>Рабочая точка</h3><br>
                  <v-row >
                    <v-col sm="6">
                      <v-text-field min="0.01" v-model="work_q" label="Q (м3/ч)" type="number" variant="outlined" density="compact" data-required></v-text-field>
                    </v-col>
                    <v-col sm="6">
                      <v-text-field min="0.01" v-model="work_h" label="H (м)" type="number" variant="outlined" density="compact" data-required></v-text-field>
                    </v-col>
                  </v-row>

                  <v-switch class="zokei_switch" v-model="zokei_off" color="var(--root-color)" label="Жокей насос"></v-switch>
                  <h3 v-if="zokei_off">Рабочая точка жокей насоса</h3><br>
                  <v-row v-if="zokei_off" >
                    <v-col sm="6">
                      <v-text-field min="0.01" v-model="work_q_z" label="Q (м3/ч)" type="number" variant="outlined" density="compact" data-required></v-text-field>
                    </v-col>
                    <v-col sm="6">
                      <v-text-field min="0.01" v-model="work_h_z" label="H (м)" type="number" variant="outlined" density="compact" data-required></v-text-field>
                    </v-col>
                  </v-row>

                  <p><span style="color: red;">*</span> - отмечены обязательные поля</p><br>
                  
                  <!--<v-switch v-model="work_point_off" color="var(--root-color)" label="Не учитывать отбор по рабочей точке"></v-switch>-->
                  <v-btn :disabled="buttonDisabled()" :loading="loading" @click="updatePumpsList()" variant="tonal" block color="var(--root-color)">Подобрать</v-btn>
                </v-col>
                <v-col class="v-col-12" xs="8" sm="8" md="6" lg="6" xl="7" >
                  <v-list v-if="items.length > 0" class="pump_list">
                    <transition-group name="list">
                      <v-list-item @click="openDialog(item)" :value="item" color="var(--root-color)" variant="tonal" :style="{ transitionDelay: item.i+'s' }" v-for="item in items" :key="item.ID" class="list-item">
                        <v-list-item-title v-text="item.UF_MODEL"></v-list-item-title>
                      </v-list-item>
                    </transition-group>
                  </v-list>
                  <div v-else>
                    <p v-if="empty_str == 'Y'">
                      <v-alert class="not_found_alert" title="" type="info">
                        По Вашему запросу не найдено подходящих насосных установок. Заполните <a href="https://adl.ru/upload/adl-doc/NO_proektirovanie_nasos_stancii.doc">Опросный лист</a> и отправьте на <a href="mailto:info@adl.ru">info@adl.ru</a> 
                      </v-alert>
                    </p>
                    <p v-else>{{ empty_str }}</p>
                  </div>
                </v-col>
                <v-col class="v-col-12" xs="12" sm="12" md="2" xl="2" lg="2">
                  <img class="select_img" :src="require('@/assets/4.png')" alt="" />
                  <img class="select_img" :src="require('@/assets/5.png')" alt="" />
                  <img class="select_img" :src="require('@/assets/6.png')" alt="" />
                  <img class="select_img" :src="require('@/assets/7.png')" alt="" />
                </v-col>
              </v-row>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen>
      <v-card>
        <v-toolbar class="pump_toolbar" color="var(--root-light)">
          <v-btn
            icon="mdi-close"
            @click="dialog = false"
          ></v-btn>

          <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>

        </v-toolbar>
        
        <GranFlowDetail :pump="active_pump" :zokei="zokei_pump" :work_point="work_point" :user_query="last_params"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import GranFlowDetail from '@/components/GranFlowDetail.vue'
import axios from "axios";



export default defineComponent({
  components: {
    GranFlowDetail
  },
  data() {
    return {
      dialog: false,
      dialogTitle: 'Насосная установка ГРАНФЛОУ',
      pump_types: ['МНС','ВМН','ЦНВ'],
      types: ['Вертикальный','Горизонтальный'],
      pump_types_filtered: new Array(0),
      types_filtered: new Array(0),
      reg_types: ['релейное регулирование','релейное регулирование с плавным пуском','частотное регулирование с одним частотным преобразователем','частотное регулирование с частотным преобразователем на каждый насос', 'частотное регулирование с одним частотным преобразователем и плавным пуском'],
      system_type: ['ХВС','ГВС', 'Отопление', 'Кондиционирование', 'Циркуляция', 'Пожаротушение Дренчерное', 'Пожаротушение Спринклерное'],
      max_temp: '',//температура
      max_pressure: '',//дпаление
      work_q: '',
      work_h: '',
      work_q_z: '',
      work_h_z: '',
      items: new Array(0),
      delay: 0,
      list_items_delay_s: 0.2,
      empty_str: "Заполните необходимые поля и нажмите \"Подобрать насосную установку\"",
      active_pump: new Object(),
      work_point: {x:-1,y:-1},
      loading: false,
      //переменные на отправку
      zokei_off: false,
      pump_name: '',
      pump_art: '',
      pump_type: '',
      pump_vid: '',
      system_type_f: '',
      reg_type: '',
      t_max_over_70: false,
      p_in: '',
      p_max: '',
      dn_vsas: '',
      dn_nap: '',
      speed_q: '',
      speed_d: '',
      water_speed: '',
      additional_auto: '',
      additional_comp: '',
      last_params: {
        Q: 0,
        H: 0,
        name: '',
        art: '',
        pumpType: '',
        vid: '',
        systemType: '',
        pumps_count_basic: 0,
        pumps_count_spare: 0,
        work_q_z: '',
        work_h_z: '',
        zokei_off: false,
        reg_type: '',
        system_type: '',
        t_max_over_70: false,//температура больше 70
        p_in: '',//входное давление
        p_max: '',//максимальное рабочее давление
        dn_vsas: '',//диаметр DNвсас
        dn_nap: '',//диаметр DNнап
        additional_auto: '',//доп требования
        additional_comp: '',//доп требования
      },
      pumps_count_basic:1,
      pumps_count_spare:0,
      zokei_pump: {
        art: '',
        name:'',
        QH: ''
      }
    }
  },
  methods:{
    async findPumps(){
      this.zokei_pump = {
        art: '',
        name:'',
        QH: ''
      }

      this.work_point.y = parseFloat(this.work_h as any)
      this.work_point.x = parseFloat(this.work_q as any)
      this.loading = true;
      this.empty_str = "Поиск..."
      try{
        const data = await axios.get('https://adl-podbor.rplp.ru/api/', {
          params: {
            type: "Units",
            cmd: "Search",
            Q: parseFloat(this.work_q),
            H: parseFloat(this.work_h),
            //pointOff: this.work_point_off,
            name: this.pump_name,//наименование
            art: this.pump_art,//артикул
            pumpType: this.pump_type,//тип
            vid: this.pump_vid,//вид
            systemType: this.system_type_f,//тип системы
            pumps_count_basic: this.pumps_count_basic,//количество насосов
            pumps_count_spare: this.pumps_count_spare,//количество резервных насосов
            work_q_z: this.work_q_z,//рабочая точка жокей насоса
            work_h_z: this.work_h_z,//рабочая точка жокей насоса
            zokei_off: this.zokei_off,//рабочая точка жокей насоса
            t_max_over_70: this.t_max_over_70,//температура больше 70
            p_in: this.p_in,//входное давление
            p_max: this.p_max,//максимальное рабочее давление
            reg_type: this.reg_type,//регулирование
            dn_vsas: this.dn_vsas,//диаметр DNвсас
            dn_nap: this.dn_nap,//диаметр DNнап
            additional_auto: this.additional_auto,//доп требования
            additional_comp: this.additional_comp,//доп требования
          }
        });

        this.last_params = {
          Q: parseFloat(this.work_q),
          H: parseFloat(this.work_h),
          name: this.pump_name,
          art: this.pump_art,
          pumpType: this.pump_type,
          vid: this.pump_vid,
          systemType: this.system_type_f,
          pumps_count_basic: this.pumps_count_basic,
          pumps_count_spare: this.pumps_count_spare,
          work_q_z: this.work_q_z,
          work_h_z: this.work_h_z,
          zokei_off: this.zokei_off,
          reg_type: this.reg_type,//регулирование
          system_type: this.system_type_f,//тип системы
          t_max_over_70: this.t_max_over_70,//температура больше 70
          p_in: this.p_in,//входное давление
          p_max: this.p_max,//максимальное рабочее давление
          dn_vsas: this.dn_vsas,//диаметр DNвсас
          dn_nap: this.dn_nap,//диаметр DNнап
          additional_auto: this.additional_auto,//доп требования
          additional_comp: this.additional_comp,//доп требования
        }


        if(data.data && data.data.data && data.data.data.pumps.length > 0){
          data.data.data.pumps.forEach((element:any, index:any) => {
            let tmp_element = element;
            Object.assign(tmp_element, {i: index*this.list_items_delay_s});
            this.items.push(tmp_element)
          })

          if(data.data && data.data.data && data.data.data.zokei && data.data.data.zokei.length > 0){
            this.zokei_pump = {
              art: data.data.data.zokei[0].UF_CODE,
              name: data.data.data.zokei[0].UF_MODEL,
              QH: data.data.data.zokei[0].UF_HEAD_H_JSON
            }
          }
        } else {
          this.empty_str = "Y"
        }

       

        
      } catch(e: any){
        console.log(e)
      }
      this.loading = false;
    },
    updatePumpsList(){
      this.items.forEach((element, index) => {
        element.i = ((this.items.length-1)-index)*this.list_items_delay_s;
      })
      /*if(this.items.length > 0){
        setTimeout(() => {
          this.items = [];       
        }, 50);
      }
      setTimeout(() => {
        this.findPumps();
      }, this.items.length*this.list_items_delay_s*1000)*/
      this.items = [];
      this.findPumps();

    },
    openDialog(pump: any){
      this.dialogTitle = pump.UF_MODEL;
      this.active_pump = pump;
      this.dialog = true;
    },
    buttonDisabled(){
      if(
        isNaN(parseFloat(this.work_q)) ||
        isNaN(parseFloat(this.work_h)) ||
        parseFloat(this.work_q) <= 0 ||
        parseFloat(this.work_h) <= 0 ||
        this.pumps_count_basic.toString().length == 0 ||
        this.pumps_count_spare.toString().length == 0 ||
        this.pumps_count_basic < 1 ||
        this.pumps_count_spare < 0 ||
        !this.system_type_f ||
        this.system_type_f.length == 0 ||
        !this.reg_type ||
        this.reg_type.length == 0 ||
        (this.zokei_off && (isNaN(parseFloat(this.work_q_z)) || isNaN(parseFloat(this.work_h_z)) || parseFloat(this.work_q_z) <= 0 || parseFloat(this.work_h_z) <= 0))

        /*((isNaN(parseFloat(this.work_q)) || isNaN(parseFloat(this.work_h)) || parseFloat(this.work_q) <= 0 || parseFloat(this.work_h) <= 0) || this.work_point_off)
        &&
        (
          (!this.pump_name || this.pump_name.length == 0) &&
          (!this.pump_art || this.pump_art.length == 0) &&
          (!this.pump_type || this.pump_type.length == 0) &&
          (!this.pump_vid || this.pump_vid.length == 0) &&
          (!this.system_type_f || this.system_type_f.length == 0)
          
        )*/ 
      ){
        return true;
      }
      return false;
    },
    calcSpeed(){
      if(parseFloat(this.speed_q) > 0 && parseFloat(this.speed_d) > 0){
        //this.water_speed = (4*parseFloat(this.speed_q)/(Math.PI*(parseFloat(this.speed_d)*parseFloat(this.speed_d)))).toFixed(2);
        let q = parseFloat(this.speed_q);
        let d = parseFloat(this.speed_d);

        console.log(q)
        console.log(d)
        this.water_speed = (q*4*1000*1000/Math.PI/d/d/3600).toFixed(2);
      }
    },
    maxWorkP(){
      if(this.system_type_f == 'Пожаротушение Дренчерное' || this.system_type_f == 'Пожаротушение Спринклерное'){
        this.p_max = parseFloat(this.p_max) > 25 ? '25' : this.p_max;
      }
    },
    filterTypes(){
      if(this.pump_type == 'МНС'){
        this.types_filtered = ['Горизонтальный']
      }
      if(this.pump_type == 'ВМН' || this.pump_type == 'ЦНВ'){
        this.types_filtered = ['Вертикальный']
      }

      if(!this.pump_type || this.pump_type.length == 0){
        this.types_filtered = this.types;
      }


      if(this.pump_vid == 'Горизонтальный'){
        this.pump_types_filtered = ['МНС']
      }
      if(this.pump_vid == 'Вертикальный'){
        this.pump_types_filtered = ['ВМН','ЦНВ']
      }
      if(!this.pump_vid || this.pump_vid.length == 0){
        this.pump_types_filtered = this.pump_types;
      }
    }

  },
  mounted() {
    this.filterTypes()
  },
  watch: {
    system_type_f(){
      this.maxWorkP()
    },
    pump_type(){
      this.filterTypes()
    },
    pump_vid(){
      this.filterTypes()
    }
  }
})
</script>
<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.pump_toolbar{
  color: var(--root-color);
}
.pump_list{
  max-height: calc(100vh - 230px);
}
.select_img{
  max-width: 100%;
  max-height: 200px;
  display: block;
  margin: 0px auto;
}
.flex_title {
  display: flex;
  justify-content: space-between;
}
.flex_title h1.text-h4{
  max-width: 100%;
  white-space: normal;
}

@media(max-width:768px){
  .flex_title {
    flex-wrap: wrap;
  }
  .flex_title h1.text-h4{
    order: +1;
  }
}
</style>
<style>
.zokei_switch .v-input__details{
  display: none;
}
</style>
<style>
.not_found_alert a{
  color: #fff;
}
.pressure .v-input__details{
  display: none;
}
</style>